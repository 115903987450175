<template>
    <div>
      <b-card>
        <list-report-head :base-url="exportTrophyCIPServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="2">
          <template v-slot:projectNameSlot>
            {{ }}
          </template>
          {{ reportTitle }}
        </list-report-head>
        <b-overlay :show="loading">
          <div class="py-2 px-2 text-center">
            <strong class="text-underline">({{ $n(1) }}) {{ $t('externalUser.export_product_sector') }} : {{ productName(listItem.product_service_sector_id) }}</strong>
          </div>
          <b-row class="mt-3">
            <b-col sm="12">
              <b-table-simple class="mt-3"  hover small bordered responsive>
                <b-thead>
                  <b-tr>
                    <b-th class="text-center align-top" width="7%">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th class="text-center align-top" width="7%">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th class="text-center align-top">{{ $t('exportTrophy.org_name_address') }}</b-th>
                    <b-th class="text-center align-top">{{ $t('exportTrophy.organization_owner_info') }}</b-th>
                    <b-th class="text-center align-top">{{ $t('exportTrophy.organization_tin_bin') }}</b-th>
                    <b-th class="text-center align-top" width="10%">{{  $t('globalTrans.comments') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="listData.length">
                    <b-tr v-for="(item, index) in listData" :key="index">
                      <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                      <b-td class="text-center">
                        <template v-if="index < 9">{{ $n(0) }}</template>{{ $n(index + 1) }}
                      </b-td>
                      <b-td>
                        <span>
                          <strong>{{ item.org_info ? (currentLocale === 'bn' ? item.org_info.org_name_bn : item.org_info.org_name_en) : '' }} </strong><br/>
                          {{ item.org_address ? AddressHelper.getCommonAddress(item.org_address) : '' }} <br>
                          {{ $t('globalTrans.phone_no') }}:
                          <template v-if="item.contact_info && item.contact_info.telephone_no">
                            {{ item.contact_info.telephone_no | numberConvert }}
                          </template>
                          <template v-else>N/A</template>
                          <br>
                          {{ $t('globalTrans.mobile') }}:
                          <template v-if="item.contact_info && item.contact_info.mobile_no">
                            {{ item.contact_info.mobile_no | numberConvert }}
                          </template>
                          <template v-else>N/A</template><br>
                          {{ $t('globalTrans.email') }}:
                          <template v-if="item.contact_info && item.contact_info.email">
                            {{ item.contact_info.email }}
                          </template>
                          <template v-else>N/A</template>
                        </span>
                      </b-td>
                      <b-td>
                        <strong>{{ currentLocale === 'en' ? item.owner_info.owner_name_en : item.owner_info.owner_name_bn }}</strong> <br>
                        {{ currentLocale === 'en' ? item.owner_info.designation_en : item.owner_info.designation_bn }} <br>
                        {{ $t('globalTrans.father_name') }}: {{ item.owner_info.father_title_en && item.owner_info.father_title_bn ? (currentLocale === 'en' ? item.owner_info.father_title_en : item.owner_info.father_title_bn) : 'N/A' }} <br>
                        {{ $t('globalTrans.mother_name') }}: {{ item.owner_info.mother_title_en && item.owner_info.mother_title_bn ? (currentLocale === 'en' ? item.owner_info.mother_title_en : item.owner_info.mother_title_bn) : 'N/A' }} <br>
                        {{ item.address ? AddressHelper.getCommonAddress(getPresentAddressData(item.address)) : '' }} <br>
                        <span class="text-underline">{{ $t('globalTrans.permanent_address') }}</span>
                        <template v-if="item.address">
                          {{ AddressHelper.getCommonAddress(getPermanentAddressData(item.address)) }}
                        </template>
                        <template v-else>N/A</template>
                      </b-td>
                      <b-td>
                        {{ $t('exportTrophy.organization_tin') }} : {{ item.org_tax_info ? $n(item.org_tax_info.tin_no, { useGrouping: false }) : '' }} <br>
                        {{ $t('exportTrophy.bin') }} : {{ item.org_info ? $n(item.org_info.bin_no, { useGrouping: false }) : '' }}
                      </b-td>
                      <b-td class="text-center">
                        {{ item.evaluation.position > 0 ? $t('exportTrophy.position') + ': ' + $n(item.evaluation.position, { useGrouping: false }) : '-' }}
                      </b-td>
                    </b-tr>
                  </template>
                  <b-tr v-else>
                    <b-td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <!-- <div class="pagination-wrapper mt-3">
                <b-pagination
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="loadData"
                />
              </div> -->
            </b-col>
        </b-row>
        </b-overlay>
      </b-card>
    </div>
</template>
<script>
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import { profileVerificationApplicantstApi } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'
import ExportPdf from './tin_bin_sheet_pdf'

export default {
  name: 'TopSheet',
  props: ['listItem'],
  components: {
    ListReportHead
  },
  data () {
    return {
      exportTrophyCIPServiceBaseUrl: exportTrophyCIPServiceBaseUrl,
      listData: [],
      fiscalYearId: 0,
      fiscalYearPrevId: 0,
      fiscalYearPrevTwoId: 0,
      search: {
        limit: 20
      },
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 20,
        slOffset: 1
      },
      AddressHelper: AddressHelper
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    reportTitle () {
      return this.currentLocale === 'en' ? 'National Export Trophy ' + this.getFiscalYearName(this.fiscalYearId) + ' Summary list of recipient organizations including company name, address, owner name, parent\'s name, permanent and current address, TIN, BIN and other details.' : 'জাতীয় রপ্তানি ট্রফি ' + this.getFiscalYearName(this.fiscalYearId) + ' প্রাপক প্রতিষ্ঠান নির্বাচন সংক্রান্ত সার-সংক্ষেপ তালিকার প্রতিষ্ঠানের নাম, ঠিকানা, মালিকের নাম, পিতা-মাতার নাম, স্থায়ী ও বর্তমান ঠিকানা, টিআইএন, বিন ও অন্যান্য তথ্যাবলীর বিবরণী।'
    }
  },
  created () {
    if (this.listItem.product_service_sector_id && this.listItem.circular_id) {
      const obj = this.$store.state.ExportTrophyCipService.commonObj.circularList.find(item => item.value === parseInt(this.listItem.circular_id))
      this.fiscalYearId = obj.fiscal_year_id
      const fiscalYear = this.fiscalYearList.find(item => item.value === parseInt(this.fiscalYearId))
      const fiscalYearPrev = this.fiscalYearList.find(item => item.sorting_order === parseInt(fiscalYear.sorting_order - 1))
      const fiscalYearPrevTwo = this.fiscalYearList.find(item => item.sorting_order === parseInt(fiscalYear.sorting_order - 2))
      this.fiscalYearPrevId = fiscalYearPrev.value
      this.fiscalYearPrevTwoId = fiscalYearPrevTwo.value
      this.loadData()
    }
  },
  methods: {
    getSectorName (id) {
      const obj = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    paginationData (data, limit) {
      this.pagination.perPage = limit
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = parseInt(this.pagination.perPage) * this.pagination.currentPage - parseInt(this.pagination.perPage) + 1
    },
    async loadData () {
      const params = Object.assign({ show_all: 1 }, this.search, this.listItem)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, profileVerificationApplicantstApi, params)
      if (result.success) {
        this.listData = result.data
        // this.paginationData(result.data, this.search.limit)
        this.totalApplication = result.total_application
      } else {
        this.listData = []
        // this.paginationData([])
        this.totalApplication = 0
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    productName (Id) {
      const Obj = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.find(item => item.value === parseInt(Id))
      return this.currentLocale === 'bn' ? Obj?.text_bn : Obj?.text_en
    },
    getCountryNames (data) {
      let names = '-'
      if (data.length) {
        const arr = []
        data.forEach(item => {
          const obj = this.$store.state.CommonService.commonObj.countryList.find(el => el.value === parseInt(item.country_id))
          arr.push(obj.text)
        })
        names = arr.toString()
      }
      return names
    },
    getProductNames (data) {
      let names = '-'
      if (data.length) {
        const arr = []
        data.forEach(item => {
          const obj = this.$store.state.ExportTrophyCipService.commonObj.productServiceTypeList.find(el => el.value === parseInt(item.product_id))
          arr.push(obj.text)
        })
        names = arr.toString()
      }
      return names
    },
    getPresentAddressData (data) {
      const outputData = {
        address_details_en: data.pr_address_details_en,
        address_details_bn: data.pr_address_details_bn,
        ward_id: data.pr_ward_id,
        union_id: data.pr_union_id,
        city_corporation_id: data.pr_city_corporation_id,
        pauroshoba_id: data.pr_paurashava_id,
        upazila_id: data.pr_upazila_id,
        district_id: data.pr_district_id,
        division_id: data.pr_division_id
      }
      return outputData
    },
    getPermanentAddressData (data) {
      const outputData = {
        address_details_en: data.pm_address_details_en,
        address_details_bn: data.pm_address_details_bn,
        ward_id: data.pm_ward_id,
        union_id: data.pm_union_id,
        city_corporation_id: data.pm_city_corporation_id,
        pauroshoba_id: data.pm_paurashava_id,
        upazila_id: data.pm_upazila_id,
        district_id: data.pm_district_id,
        division_id: data.pm_division_id
      }
      return outputData
    },
    pdfExport () {
      const reportTitle = this.reportTitle
      return ExportPdf.exportPdfDetails(exportTrophyCIPServiceBaseUrl, '/configuration/report-heading/detail', 2, reportTitle, this)
    }
  }
}
</script>
<style scoped>
.list-disc {
  list-style-type: disc;
  padding-left: 1rem;
}
</style>
