import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import AddressHelper from '@/utils/area-type-address'
import { numberConvert } from '@/utils/fliter'

function getOrganizationInfoText (vm, item) {
  let textContent = ''
  if (item.org_info) {
    if (i18n.locale === 'bn') {
      textContent += item.org_info.org_name_bn + '\n'
    } else {
      textContent += item.org_info.org_name_en + '\n'
    }
  }
  let textContent2 = ''
  if (item.org_address) {
    textContent2 += AddressHelper.getCommonAddress(item.org_address) + '\n'
  }
  textContent2 += vm.$t('globalTrans.phone_no') + ': '
  if (item.contact_info && item.contact_info.telephone_no) {
    textContent2 += numberConvert(item.contact_info.telephone_no) + '\n'
  }
  textContent2 += vm.$t('globalTrans.mobile') + ': '
  if (item.contact_info && item.contact_info.mobile_no) {
    textContent2 += numberConvert(item.contact_info.mobile_no) + '\n'
  }
  textContent2 += vm.$t('globalTrans.email') + ': '
  if (item.contact_info && item.contact_info.email) {
    textContent2 += numberConvert(item.contact_info.email)
  }
  const customText = [
    { text: textContent, style: 'td', alignment: 'left', bold: true },
    { text: textContent2, style: 'td', alignment: 'left' }
  ]
  return customText
}

function getOwnerInfoText (vm, item) {
  const currentLocale = vm.currentLocale
  let textContent = ''
  if (item.owner_info) {
    if (i18n.locale === 'bn') {
      textContent += item.owner_info.owner_name_bn + '\n'
    } else {
      textContent += item.owner_info.owner_name_en + '\n'
    }
  }
  let textContent2 = ''
  if (item.owner_info) {
    textContent2 += (currentLocale === 'en' ? item.owner_info.designation_en : item.owner_info.designation_bn) + '\n'
  }
  textContent2 += vm.$t('globalTrans.father_name') + ': '
  if (item.owner_info && item.owner_info.father_title_en && item.owner_info.father_title_bn) {
    textContent2 += (currentLocale === 'en' ? item.owner_info.father_title_en : item.owner_info.father_title_bn) + '\n'
  } else {
    textContent2 += 'N/A' + '\n'
  }
  textContent2 += vm.$t('globalTrans.mother_name') + ': '
  if (item.owner_info && item.owner_info.mother_title_en && item.owner_info.mother_title_bn) {
    textContent2 += (currentLocale === 'en' ? item.owner_info.mother_title_en : item.owner_info.mother_title_bn) + '\n'
  } else {
    textContent2 += 'N/A' + '\n'
  }
  if (item.address) {
    textContent2 += AddressHelper.getCommonAddress(vm.getPresentAddressData(item.address)) + '\n'
  }
  const textContent3 = vm.$t('globalTrans.permanent_address') + ': '
  const textContent4 = item.address ? AddressHelper.getCommonAddress(vm.getPermanentAddressData(item.address)) : 'N/A'
  const customText = [
    { text: textContent, style: 'td', alignment: 'left', bold: true },
    { text: textContent2, style: 'td', alignment: 'left' },
    { text: textContent3, style: 'td', alignment: 'left', decoration: 'underline' },
    { text: textContent4, style: 'td', alignment: 'left' }
  ]
  return customText
}

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
      ]
      pdfContent.push({ text: '(' + vm.$n(1) + ') ' + vm.$t('externalUser.export_product_sector') + ': ' + vm.productName(vm.listItem.product_service_sector_id), alignment: 'center', style: 'header2' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.org_name_address'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.organization_owner_info'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.organization_tin_bin'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.comments'), style: 'th', alignment: 'center', bold: true }
        ]
      ]
      if (vm.listData.length) {
        vm.listData.forEach((item, index) => {
          const customText = vm.$t('exportTrophy.organization_tin') + ': ' + (item.org_tax_info ? vm.$n(item.org_tax_info.tin_no, { useGrouping: false }) : '') + '\n' +
          vm.$t('exportTrophy.bin') + ': ' + (item.org_info ? vm.$n(item.org_info.bin_no, { useGrouping: false }) : '')
          allRows.push(
            [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: (index < 9 ? vm.$n(0) : '') + vm.$n(index + 1), style: 'td', alignment: 'center' },
              {
                text: getOrganizationInfoText(vm, item),
                style: 'td',
                alignment: 'center'
              },
              {
                text: getOwnerInfoText(vm, item),
                style: 'td',
                alignment: 'center'
              },
              { text: customText, style: 'td', alignment: 'left' },
              { text: item.evaluation.position > 0 ? (vm.$t('exportTrophy.position') + ': ' + vm.$n(item.evaluation.position, { useGrouping: false })) : '-', style: 'td', alignment: 'center' }
            ]
          )
        })
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['5%', '5%', 'auto', 'auto', 'auto', 'auto'],
          body: allRows
        }
      })
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 8 : 7,
          margin: [1, 1, 1, 1]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 8 : 7,
          margin: [1, 1, 1, 1]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 10 : 11,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        org: {
            fontSize: 13,
            bold: true,
            margin: [0, -25, 10, 5]
        },
        address: {
            fontSize: 11,
            margin: [0, 0, 0, 5]
        },
        hh: {
            fontSize: 13,
            bold: true,
            margin: [10, 10, 25, 20]
        },
        header2: {
          fontSize: 10,
          margin: [0, 10, 0, 20],
          bold: true,
          decoration: 'underline'
        },
        header3: {
          fontSize: 10,
          margin: [0, 6, 0, 0]
        },
        header4: {
          fontSize: 9,
          margin: [0, 6, 0, 9]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 11]
        },
        krishi: {
          margin: [0, -5, 0, 11],
          alignment: 'center'
        },
        tableSubHead: {
          margin: [0, 5, 0, 11]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
